import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Container from "layouts/Container";
import Hero from "components/Hero";
import Cta from "components/Cta";

const About = ({ data: { page, footerImage } }) => {
  const { t } = useTranslation();
  const { seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />

      <Container className="container--t-margin-sm container--b-margin-md" noOverflow={false}>
        <Hero
          title={t("About")}
          content={
            <p className="t3">
              Our mission is to nurture cutting-edge applications for decentralized web software protocols. <br />
              <br />
              Our passion is delivering Web 3.0, a decentralized and fair internet where users control their own data,
              identity and destiny.
            </p>
          }
          asideTitle="Web3 Foundation believes in an internet where:"
          asideContent={
            <ul>
              <li>Users own their own data, not corporations</li>
              <li>Global digital transactions are secure</li>
              <li>Online exchanges of information and value are decentralized</li>
            </ul>
          }
        />
      </Container>
      <Container className="container--v-margin-lg">
        <Cta
          title="Want to learn more about our blockchain protocols and open-source work?"
          link={{
            to: "/projects/",
            title: "Web3 Foundation Projects",
          }}
        />
      </Container>
    </Layout>
  );
};

export default About;

export const query = graphql`
  fragment MemberImage on File {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], placeholder: NONE)
    }
  }

  query ($language: String!) {
    ...Locales
    page: markdownRemark(fileAbsolutePath: { regex: "//pages/about.md/" }) {
      frontmatter {
        councilMembers: council_members
        seo {
          ...Seo
        }
      }
    }
    allCouncilMembers: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//members//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          image {
            ...MemberImage
          }
          name
          job_title
          description
        }
      }
    }
    footerImage: file(name: { eq: "about-large" }) {
      ...BgImage
    }
  }
`;
